// @ts-nocheck
import BaseView from 'base_view';
import routingNumberValidator from 'views/contributions/account_info/validation/rails.validations.routing_number';
import ClientSideValidations from 'rails.validations';
import TokenexEncrypt from '@appfolio/payments-utils';

export default class AchView extends BaseView {
  constructor() {
    super();
    ClientSideValidations.validators.local.routing_number = routingNumberValidator;
    this.markPageLoaded();

    let companyName = document.getElementById('js-ach-payment-company-name');
    companyName.classList.add('u-hidden');
    companyName.querySelector('input').disabled = true;
    for (const element of document.getElementsByClassName('js-radio-payment-type-toggle')) {
      element.addEventListener('change', () => {
        const firstName = document.getElementById('js-ach-payment-first-name');
        const lastName = document.getElementById('js-ach-payment-last-name');
        companyName = document.getElementById('js-ach-payment-company-name');
        if (firstName.matches('.u-hidden')) {
          firstName.classList.remove('u-hidden');
          firstName.querySelector('input').disabled = false;
          lastName.classList.remove('u-hidden');
          lastName.querySelector('input').disabled = false;
          companyName.classList.add('u-hidden');
          companyName.querySelector('input').disabled = true;
        } else {
          firstName.classList.add('u-hidden');
          firstName.querySelector('input').disabled = true;
          lastName.classList.add('u-hidden');
          lastName.querySelector('input').disabled = true;
          companyName.classList.remove('u-hidden');
          companyName.querySelector('input').disabled = false;
        }
      });
    }

    $('#js-continue-tokenize').on('click', async function (e) {
      e.preventDefault();
      const currentForm = $('#new_forms_account_info_form');

      // required to retrigger custom routingNumberValidator
      $('#forms_account_info_form_routing_number').data('changed', true);
      if (currentForm.isValid(currentForm[0].ClientSideValidations.settings.validators)) {
        var publicKey = $('#js-token-key').get(0).value;
        var accountNumber = $('#forms_account_info_form_account_number').get(0).value;
        if (accountNumber.length > 0) {
          var cipherText = await TokenexEncrypt.tokenexEncrypt(publicKey, accountNumber);
          $('#forms_account_info_form_encrypted_account_number').val(cipherText);
          $('#forms_account_info_form_account_number').get(0).value = accountNumber.substring(accountNumber.length - 4);
        }
        currentForm.submit();
      }
    });
  }
}
